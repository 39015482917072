import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import { Container, Row, Col } from 'react-bootstrap';

import './style.scss';

const ProntoPartnership = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Pronto Marketing + GlassHive Partnership"
                description="Digital marketing and technology experts, what can get much better? Find out. "
            />
            <div className="pronto-partnership-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                Pronto Marketing + <br /> GlassHive Partnership
                            </h1>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="two-fifth ">
                            <h3 className="centered-mobile purple-text max-width-500">
                                Our way of working together
                            </h3>
                            <p>
                                This partnership hopes to bring a holistic
                                marketing offering to tech businesses of every
                                size by combining the scalable sales and
                                marketing platform of GlassHive with Pronto
                                Marketing's unique small business marketing
                                services.
                            </p>
                        </Col>
                        <Col className="three-fifth centered-text">
                            <img
                                src={require('../../images/pronto-partnership/partner-logos.png')}
                                alt="glasshive and pronto logos"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row className="three-fifth">
                        <Col className="centered-text white-text max-width-550">
                            <h3>We're hosting a webinar!</h3>
                            <p className="nunito">
                                Lucky for you! We’re hosting a webinar about the
                                top 5 mistakes technology companies make that
                                prevent killer growth. Sound like something
                                you’re interested in? Sign up for the event
                                below.
                            </p>
                            <a
                                href="https://honeycrm.webinarninja.com/live-webinars/3826198/register?in_tok=17a124f3-2ce9-4533-981f-a45b3b11c288"
                                target="_blank"
                            >
                                Register for the Webinar
                            </a>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4 purple-background">
                    <Row>
                        <Col className="two-fifth centered-text">
                            <img
                                src={require('../../images/pronto-partnership/marketing-director.png')}
                                alt="Marketing director Tim Kelsey"
                            />
                        </Col>
                        <Col className="three-fifth white-text">
                            <p>
                                "GlassHive and Pronto share a common mission:
                                Help IT companies execute their sales &
                                marketing campaigns," says Tim Kelsey, Managing
                                Director at Pronto Marketing. "GlassHive is a
                                great solution that works seamlessly with the
                                website & marketing platforms IT companies
                                already use. Our customers that use GlassHive
                                have been successful at automating their email
                                marketing, staying on top of each sales
                                activity, and tracking results."
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-5 purple-background">
                    <Row>
                        <Col className="max-width-700" id="form">
                            <AppForm
                                explainerText={`
                                  <h3 class="centered-text">Learn how Pronto can help your business</h3>
                                  <p class="centered-text white-text">
                                      We’re with you. For the long haul and
                                      beyond. We’ve helped thousands of IT
                                      Solution Providers in multiple countries and
                                      we leverage that experience in all we do to
                                      help you.
                                  </p>
                                `}
                                formName="Pronto Partnership"
                            >
                                <AppInput
                                    fieldName="name"
                                    type="text"
                                    placeholder="Name"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    placeholder="Email"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="company"
                                    type="text"
                                    placeholder="Company"
                                    requiredField
                                />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default ProntoPartnership;
